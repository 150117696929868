import axios from "axios";

class Settings {
    constructor(apiUrl) {
        this.apiUrl = apiUrl
    }

    async get() {
        let settings = await axios.get(`${this.apiUrl}/settings`)
        return settings.data.payload
    }

    async getHomepage() {
        let settings = await axios.get(`${this.apiUrl}/settings/homepage`)
        return settings.data.payload
    }

    async getLoginBanner() {
        let settings = await axios.get(`${this.apiUrl}/settings/getLoginBanner`)
        return settings.data.payload
    }

    async syncDB() {
        let settings = await axios.get(`${this.apiUrl}/settings/initiateSync`)
        return settings.data.payload
    }

    async getDownloadLinks() {
        let settings = await axios.get(`${this.apiUrl}/settings/getDownloadLinks`)
        return settings.data.payload
    }

    async getHeader() {
        let settings = await axios.get(`${this.apiUrl}/settings/header`)
        return settings.data.payload
    }

    async saveUserPassword(userId, password) {
        await axios.post(`${this.apiUrl}/settings/userPassword`, {
            userId,
            password,
        })
    }

    async saveMenu(menuItems) {
        let settingsResponse = await axios.post(`${this.apiUrl}/settings/saveMenu`, {
            menuItems
        })

        return settingsResponse.data.payload
    }

    async saveMenuCZ(menuItems) {
        let settingsResponse = await axios.post(`${this.apiUrl}/settings/saveMenuCZ`, {
            menuItems
        })

        return settingsResponse.data.payload
    }

    async saveMenuSimone(menuItems) {
        let settingsResponse = await axios.post(`${this.apiUrl}/settings/saveMenuSimone`, {
            menuItems
        })

        return settingsResponse.data.payload
    }

    async save(settings) {
        let settingsResponse = await axios.post(`${this.apiUrl}/settings`, {
            settings
        })
        return settingsResponse.data.payload
    }

    async saveHomepage(settings) {
        let settingsResponse = await axios.post(`${this.apiUrl}/settings/saveHomepage`, {
            settings
        })
        return settingsResponse.data.payload
    }

    async saveHeader(settings) {
        let settingsResponse = await axios.post(`${this.apiUrl}/settings/saveHeader`, {
            settings
        })
        return settingsResponse.data.payload
    }

    async updateUserSecondEmail(userId, allowSecondEmail) {
        await axios.post(`${this.apiUrl}/settings/userSecondEmail`, {
            userId,
            allowSecondEmail,
        })
    }

    async getUsers() {
        let users = await axios.get(`${this.apiUrl}/settings/users`)
        return users.data.payload
    }
}

export default Settings
